import { AppContentSchema, Children, Children2, ContextHelpMappingFileSchema, LanguagesFileSchema, UrlParams } from "./file-schemas"
var semver = require('semver');
var semverSort = require('semver-sort');
var path = require('path');

const languageMapping = {
    "cs": ["cs", "cs-CZ"],
    "de": ["de", "de-AT", "de-BE", "de-CH", "de-DE", "de-IT", "de-LI", "de-LU"],
    "en": ["en", "en-001", "en-150", "en-AG", "en-AI", "en-AS", "en-AT", "en-AU", "en-BB", "en-BE", "en-BI", "en-BM", "en-BS", "en-BW", "en-BZ", "en-CA", "en-CC", "en-CH", "en-CK", "en-CM", "en-CX", "en-CY", "en-DE", "en-DG", "en-DK", "en-DM", "en-ER", "en-FI", "en-FJ", "en-FK", "en-FM", "en-GB", "en-GD", "en-GG", "en-GH", "en-GI", "en-GM", "en-GU", "en-GY", "en-HK", "en-IE", "en-IL", "en-IM", "en-IN", "en-IO", "en-JE", "en-JM", "en-KE", "en-KI", "en-KN", "en-KY", "en-LC", "en-LR", "en-LS", "en-MG", "en-MH", "en-MO", "en-MP", "en-MS", "en-MT", "en-MU", "en-MW", "en-MY", "en-NA", "en-NF", "en-NG", "en-NL", "en-NR", "en-NU", "en-NZ", "en-PG", "en-PH", "en-PK", "en-PN", "en-PR", "en-PW", "en-RW", "en-SB", "en-SC", "en-SD", "en-SE", "en-SG", "en-SH", "en-SI", "en-SL", "en-SS", "en-SX", "en-SZ", "en-TC", "en-TK", "en-TO", "en-TT", "en-TV", "en-TZ", "en-UG", "en-UM", "en-US", "en-US-POSIX", "en-VC", "en-VG", "en-VI", "en-VU", "en-WS", "en-ZA", "en-ZM", "en-ZW"],
    "es": ["es", "es-419", "es-AR", "es-BO", "es-BR", "es-BZ", "es-CL", "es-CO", "es-CR", "es-CU", "es-DO", "es-EA", "es-EC", "es-ES", "es-GQ", "es-GT", "es-HN", "es-IC", "es-MX", "es-NI", "es-PA", "es-PE", "es-PH", "es-PR", "es-PY", "es-SV", "es-US", "es-UY", "es-VE"],
    "fr": ["fr", "fr-BE", "fr-BF", "fr-BI", "fr-BJ", "fr-BL", "fr-CA", "fr-CD", "fr-CF", "fr-CG", "fr-CH", "fr-CI", "fr-CM", "fr-DJ", "fr-DZ", "fr-FR", "fr-GA", "fr-GF", "fr-GN", "fr-GP", "fr-GQ", "fr-HT", "fr-KM", "fr-LU", "fr-MA", "fr-MC", "fr-MF", "fr-MG", "fr-ML", "fr-MQ", "fr-MR", "fr-MU", "fr-NC", "fr-NE", "fr-PF", "fr-PM", "fr-RE", "fr-RW", "fr-SC", "fr-SN", "fr-SY", "fr-TD", "fr-TG", "fr-TN", "fr-VU", "fr-WF", "fr-YT", "br"],
    "hu": ["hu", "hu-HU"],
    "it": ["it", "it-CH", "it-IT", "it-SM", "it-VA"],
    "ja": ["ja", "ja-JP"],
    "ko": ["ko", "ko-KP", "ko-KR"],
    "nl": ["nl", "nl-AW", "nl-BE", "nl-BQ", "nl-CW", "nl-NL", "nl-SR", "nl-SX"],
    "pl": ["pl", "pl-PL"],
    "ptBR": ["pt", "pt-AO", "pt-BR", "pt-CH", "pt-CV", "pt-GQ", "pt-GW", "pt-LU", "pt-MO", "pt-MZ", "pt-PT", "pt-ST", "pt-TL"],
    "ru": ["ru", "ru-BY", "ru-KG", "ru-KZ", "ru-MD", "ru-RU", "ru-UA", "uk"],
    "zhchs": ["zh", "zh-Hans", "zh-Hans-CN", "zh-Hans-HK", "zh-Hans-MO", "zh-Hans-SG", "zh-CN"],
    "zhcht": ["zh-Hant", "zh-Hant-HK", "zh-Hant-MO", "zh-Hant-TW", "zh-TW"]
  }

export const mapperService = (fetchJson: (url: string) => Promise<any>) => {
    var allContent: AppContentSchema;

    const createUrl = async (params: UrlParams, content: AppContentSchema): Promise<string> => {
        allContent = content;

        var noComponentSelected = !params.component;
        if (noComponentSelected) {
            return getFallbackComponentPath();
        }

        var componentItem = content.children.find(x => x.name.replaceAll('_', '') === params.component?.toLowerCase().replaceAll('_', ''));
        const componentOrVersionsDoesNotExist = !componentItem || !componentItem.children || componentItem.children.length === 0;
        if (componentOrVersionsDoesNotExist) {
            return getFallbackComponentPath();
        }

        const noVersionSelected = !params.version;
        var versionItem: Children;
        if (noVersionSelected) {
            versionItem = getLatestComponentVersionItem(undefined, componentItem!.children!)!;
        } else {
            versionItem = getComponentVersion(componentItem!.children!, params.version!)!;
        }

        var languageSettings = await getLanguageSettings(componentItem!, versionItem!, params.language);
        if (!languageSettings) {
            return getFallbackComponentPath();
        }

        var helpUrl = combinePartsToUrl(languageSettings, componentItem!, versionItem!, params.helpId) ?? getFallbackComponentPath();

        return helpUrl;
    };

    function matchesLanguage(languageRegex: string, language: string) {
        const regex = new RegExp(languageRegex);
        return regex.test(language.toLowerCase());
    }

    async function fetchJsonTyped<DOCTYPE>(url: string): Promise<DOCTYPE> {
        const value: DOCTYPE = await fetchJson(url);
        return value;
    }

    const getComponentVersion = (versionItems: NonNullable<Children2>[], version: string): Children2 | undefined => {
        var threeDotsInVersion = (version.match(/\./g) || []).length === 3;
        if (threeDotsInVersion) {
            version = version.substr(0, version.lastIndexOf("."));
        }

        var versions = versionItems.map(x => x.name);
        var maxSatisfyingComponentVersion = semver.maxSatisfying(versions, version);

        var counter = 0;
        var versionPart = '';
        while (!maxSatisfyingComponentVersion && version.lastIndexOf('.') !== -1) {
            counter++;
            version = version.slice(0, version.lastIndexOf('.'));
            versionPart = version;
            for (let i = 0; i < counter; i++) {
                versionPart = versionPart + '.x';
            }

            maxSatisfyingComponentVersion = semver.maxSatisfying(versions, versionPart);
        };

        if (!maxSatisfyingComponentVersion) {
            var latestVersion = semverSort.desc(versions)[0];
            return versionItems.find(x => x.name === latestVersion);
        }

        return versionItems.find(x => x.name === maxSatisfyingComponentVersion);
    };

    const combinePartsToUrl = async (languageSettings: LanguagesFileSchema["Languages"][0], componentItem: Children, versionItem: Children, helpId: string | null) => {
        var url: string = '';
        const versionPath = versionItem.path.replace(/\\/g, '/');
        const isDirectResolver = languageSettings.ResolverType === "direct";
        if (isDirectResolver) {
            if (!helpId) {
                var designUrlParams = { component: 'design', version: null, language: null, helpId: null };
                return createUrl(designUrlParams, allContent);
            }

            const directBaseUrl = path.join(versionPath, languageSettings.DirectTargetBaseDir, helpId + (languageSettings.DirectTargetSuffix ?? ""));
            return directBaseUrl;
        }

        const noHelpId = !helpId;
        if (!url && noHelpId) {
            var languageFallbackUrl = path.join(versionPath, languageSettings.NoMappingFallbackPage);

            url = languageFallbackUrl;
        }

        const mappingBaseDirMissing = !languageSettings.MappingTargetBaseDir;
        if (!url && mappingBaseDirMissing) {
            const latestVersionItem = getLatestComponentVersionItem(undefined, [versionItem!]);
            const latestComponentVersionUrl = path.join(versionPath, latestVersionItem?.name);

            url = latestComponentVersionUrl;
        }
        if (!url) {
            const helpIdMapFile = path.join(versionPath, languageSettings.HelpIdMappingFile);
            const helpIdMap = await fetchJsonTyped<ContextHelpMappingFileSchema[]>(helpIdMapFile);
            const helpIdItem = helpIdMap.find((item) => item.HelpID === helpId);
            if (helpIdItem) {
                var helpIdUrl = path.join(versionPath, languageSettings.MappingTargetBaseDir, helpIdItem.HelpUrl);

                url = helpIdUrl;
            }
        }

        return url;
    }

    const getLatestComponentVersionItem = (componentItem?: Children, versions?: Children2[]) => {
        if (versions) {
            const versionsSortedLatestFirst = semverSort.desc(versions.map(x => x.name));
            const latestVersion = versionsSortedLatestFirst[0];
            if (!latestVersion) {
                return null;
            }

            var latestVersionItem = versions.find(x => x.name === latestVersion);
            return latestVersionItem;
        }

        if (componentItem) {
            const versionsSortedLatestFirst = semverSort.desc(componentItem?.children?.map(x => x.name));
            const latestVersion = versionsSortedLatestFirst[0];
            if (!latestVersion) {
                return null;
            }

            return componentItem?.children?.find(x => x.name === latestVersion);
        }
    }

    async function getFallbackComponentPath(): Promise<string> {
        var contentFolderPath = process.env.REACT_APP_CONTENT_FOLDER_PATH;
        var defaultComponent = process.env.REACT_APP_DEFAULT_COMPONENT;
        var componentItem = allContent.children.find(x => x.name.replaceAll('_', '') === defaultComponent!.toLowerCase());
        var latestComponentVersionItem = getLatestComponentVersionItem(componentItem!, undefined);
        var languageSettings = await getLanguageSettings(componentItem!, latestComponentVersionItem!, null);
        const languageFallbackUrl = path.join(contentFolderPath, defaultComponent, latestComponentVersionItem?.name, languageSettings?.NoMappingFallbackPage);

        return languageFallbackUrl;
    }

    async function getLanguageSettings(component: Children, versionItem: Children2, selectedLanguage: string | null): Promise<LanguagesFileSchema["Languages"][0] | undefined> {
        const languageJsonPath = (path.join("content", component.name, versionItem.name, "languages.json")).replace(/\\/g, '/');
        const languageSettingsList = await fetchJsonTyped<LanguagesFileSchema>(languageJsonPath);

        var languageSettings;
        if (selectedLanguage) {
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, selectedLanguage));
        }

        if (!languageSettings) {
            let existingLanguage = mapBrowserLanguageToExistingLangages(navigator.language);
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, existingLanguage));
        }

        if (!languageSettings && languageSettingsList.FallbackLanguage) {
            let fallbackLanguage = languageSettingsList.FallbackLanguage;
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, fallbackLanguage));
        }

        if (!languageSettings) {
            let defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE!;
            languageSettings = languageSettingsList.Languages.find(langItem => matchesLanguage(langItem.LanguageRegex, defaultLanguage));
        }

        if (!languageSettings && languageSettingsList.Languages[0]?.NoMappingFallbackPage) {
            languageSettings = languageSettingsList.Languages[0];
        }

        return languageSettings;
    }
    
    function mapBrowserLanguageToExistingLangages(languageCode: string): string {
        for (const [key, codes] of Object.entries(languageMapping)) {
            if (codes.includes(languageCode)) {
                return key;
            }
        }
        return '';
    }

    async function getContentJson(): Promise<AppContentSchema> {
        const contentPath = process.env.REACT_APP_CONTENT_FOLDER_PATH;
        var appContentPath = path.join(contentPath, "appContent.json");
        var content = await (fetchJson(appContentPath).catch(() => false));

        return content;
    }

    return {
        createUrl, getContentJson
    };
};

async function fetchJson(url: string): Promise<any> {
    var domainPath = process.env.REACT_APP_DOMAIN!;
    url = url.includes(domainPath) ? url : domainPath + url.replace(/\\/g, '/');

    const response = await fetch(url);
    if (!response.ok) {
        throw new Error("Failed to fetch '" + url + "': " + response.statusText);
    }
    const text = await response.text();
    return JSON.parse(text);
};

export const mapperServiceWithDefaultFetchJson = mapperService(fetchJson);
export { fetchJson };